body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media print {
    html,
    body {
        border: 1px solid white;
        height: 99%;
        page-break-after: avoid;
        page-break-before: avoid;
    }
}

* {
    /* transition: all 0.2s ease-in; */
}

.Select-input > input {
    width: 1000px !important;
}

button.button {
    margin: 2px !important;
}

#root {
    height: 100%;
}

.react-calendar {
    border: unset !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.rowSelected {
    background-color: #a9fcdf !important;
}

.rowSelected:hover,
.ui.selectable.table tbody tr.rowSelected:hover {
    background-color: #d7fff1 !important;
}

span {
    overflow: hidden;
    text-overflow: ellipsis;
}

.react-view:before,
.react-view:after {
    display: none !important;
}

.fullWidthDatePicker,
.fullWidthDatePicker div.field,
.fullWidthDatePicker div.input,
.fullWidthDatePicker input {
    width: 100%;
    text-align: center !important;
}

.ui.statistic > .value.smallStatistic {
    font-size: 2.5rem !important;
}

.ui.statistic > .value.smallestStatistic {
    font-size: 2rem !important;
}

.rightTextField > div.input > input {
    text-align: right;
}

.noScrollBars {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.noScrollBars::-webkit-scrollbar {
    display: none;
}

.sidebarContainer {
    max-width: 120px !important;
}

.only-print,
only-print * {
    display: none !important;
}

.chat-item > img {
    height: 50px !important;
    width: 50px !important;
}

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }

    .only-print,
    only-print * {
        display: inherit !important;
    }
}

div.rc-slider-track.rc-slider-track-1 {
    background-color: #55aa61;
}

span.rc-slider-dot.rc-slider-dot-active {
    border-color: #55aa61;
}

div.rc-slider-handle,
div.rc-slider-handle.rc-slider-handle-click-focused {
    border-color: #55aa61;
}

.ui.menu:not(.vertical) .left.item.noRightMargin,
.ui.menu:not(.vertical) :not(.dropdown) > .left.menu {
    /* display: flex; */
    margin-right: 0px !important;
}

.react-grid-item {
    margin: 0px !important;
}

.droppableContainer > div {
    width: 100%;
    height: 150%;
}

.ui.form > .some-margin > .text.no-margin {
    display: none;
}

/* 
.dashboardHeader .ui.statistic {
	margin: 0px !important;
} */

.leaflet-control-attribution {
    display: none;
}

.location-picker > .leaflet-container {
    height: 600px !important;
}

.round {
    position: relative;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
}

.round label:before {
    display: none;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
    content: '' !important;
}

.round input[type='checkbox'] {
    visibility: hidden;
}

.round input[type='checkbox']:checked + label {
    background-color: #66bb6a;
    border-color: #66bb6a;
}

.round input[type='checkbox']:checked + label:after {
    opacity: 1;
}

.hidden {
    display: none;
}

.completed-cell {
    /* background-image: linear-gradient(45deg, #5edb5a 25%, #126e19 25%, #126e19 50%, #5edb5a 50%, #5edb5a 75%, #126e19 75%, #126e19 100%);
    background-size: 56.57px 56.57px; */
    background-color: #126e19;
}

.error-cell {
    background-image: linear-gradient(45deg, #db5a5a 25%, #6e1212 25%, #6e1212 50%, #db5a5a 50%, #db5a5a 75%, #6e1212 75%, #6e1212 100%);
    background-size: 56.57px 56.57px;
}

.warning-cell {
    /* background-image: linear-gradient(45deg, #fede00 47.62%, #94781e 47.62%, #94781e 50%, #fede00 50%, #fede00 97.62%, #94781e 97.62%, #94781e 100%);
    background-size: 29.7px 29.7px; */
    background-color: #fede75;
}

.empty-cell {
    /* background-image: linear-gradient(45deg, #bfbfbf 25%, #000000 25%, #000000 50%, #bfbfbf 50%, #bfbfbf 75%, #000000 75%, #000000 100%);
    background-size: 56.57px 56.57px; */
    background-color: gray;
}

/*** Overrides for react-virtualized styles ***/
.ReactVirtualized__Table__Grid {
    background-color: white;
    border-bottom: 1px solid #d3d3d3;
    border-right: 1px solid #d3d3d3;
}

.ReactVirtualized__Table__headerColumn {
    align-items: center;
    border-right: 1px solid #d3d3d3;
    display: flex;
    flex: 1;
    height: 100%;
    min-width: 0;
}

.ReactVirtualized__Table__headerRow {
    background-image: linear-gradient(#fff, #efefef);
    border: 1px solid #d3d3d3;
    padding-right: 0 !important;
}

.ReactVirtualized__Table__row {
    border-bottom: 1px solid #d3d3d3;
    border-left: 1px solid #d3d3d3;
}

.ReactVirtualized__Table__rowColumn {
    align-items: center;
    border-right: 1px solid #d3d3d3;
    display: flex;
    flex: 1;
    height: 100%;
    min-width: 0;
}

div.noflexdiv {
    display: block !important;
}

span.label {
    display: block;
    margin: 0 0 0.28571429rem 0;
    /* color: rgba(0, 0, 0, 0.87); */
    font-size: 0.92857143em;
    font-weight: 700;
    text-transform: none;
}

.perma-light-mode div {
    border-color: black !important;
    color: black !important;
    background-color: white !important;
}

div.inverted-scrollbar {
    transform: translate(-50%, -50%) rotateX(180deg) translate(50%, 50%);
}
div.inverted-scrollbar * {
    transform: translate(-50%, -50%) rotateX(180deg) translate(50%, 50%);
}
